import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-not-available',
  templateUrl: './not-available.component.html',
  styleUrls: []
})
export class NotAvailableComponent implements OnInit {
  constructor(private router: Router) { }
  ngOnInit() {
  }
  btnclick(val: number) {
    if (val === 0) {
      this.router.navigate(["sharePicInSomeTime"]);
    } else if(val===1) {
      this.router.navigate(["alternateNo"]);
    }else{
      this.router.navigate(["first"]);
    }
  }
}
