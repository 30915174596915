import axios from "axios";
import { IFtpData,IvalidateToken ,IFtpImage , updateStatus} from './type'

import { APIURL } from './sessionGlobalVar';
export class ApiService {
    public static async FtpDataUpload(input: IFtpData) {
        return new Promise((resolve, reject) => {
            axios.post(`${APIURL}api//FTPDATAUpload`, input).then((response) => {
                resolve(response);
            }, (err) => {
                reject(err);
            });
        });
    }
    public static async ValidateToken(input: IvalidateToken) { 
        debugger;      
        return new Promise((resolve, reject) => {            
            axios.post(`${APIURL}api//ValidateToken`, input).then((response) => {
                debugger;                
                resolve(response);
            }, (err) => {               
                debugger;
                reject(err);
            });
        });
    }
    public static async updateStatus(input: updateStatus) {       
        return new Promise((resolve, reject) => {            
            axios.post(`${APIURL}api//Updateftpstatus`, input).then((response) => {                
                resolve(response);
            }, (err) => {               
                reject(err);
            });
        });
    }
    public static async UploadFTPImage(input: IFtpImage) {       
        return new Promise((resolve, reject) => {            
            axios.post(`${APIURL}api//UploadFTPImage`, input).then((response) => {                
                resolve(response);
            }, (err) => {               
                reject(err);
            });
        });
    }

    
}
//GetBankDetails
export default ApiService;
