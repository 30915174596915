import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { IFtpImage } from '../../service/type';
import ApiService from 'src/service/service';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-external-photo',
  templateUrl: './external-photo.component.html',
  styleUrls: []
})
export class ExternalPhotoComponent implements OnInit {
  // toggle webcam on/off
  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  fBuilding: boolean = false;
  mainGate: boolean = false;
  showMainGate: boolean = false;
  showNext: boolean = false;
  IfbuildingShow: boolean = true;
  showButton: boolean = false;
  btntext: string = "Take a snapshoot";
  btntitel: string = "Capture snapshoot front of building";
  snapType: string = "ExternalBuildingIMage";
  uiImageTest: string = "Front Of Building"
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  public frontOFBuilding: any;
  public imageBase64Building: any;

  public frontOFMainGate: any;
  public imageBase64MainGate: any;

  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  imagetype: string;
  valueOFImage: string;
  ISimageshow: boolean = false;
  latitude: string;
  longitude: string

  constructor(private router: Router, private ngxService: NgxUiLoaderService) {
    this.getPosition();
  }
  ngOnInit() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        if (this.multipleWebcamsAvailable) {
          this.videoOptions = {
            facingMode: 'environment' // Instruct the browser to use the back camera
          };
        }
      });
  }
  getPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = (position.coords.latitude).toString();
        this.longitude = (position.coords.longitude).toString();
        resolve(position.coords);
      }, (err) => {
        reject(err);
      });
    });
  }

  MoveHome() {
    this.router.navigate(["home"]);
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.ISimageshow = false;
    this.IfbuildingShow = false;
    this.showButton = true;
    this.btntext = "Recapture";
    this.btntitel = "Recapture snapshoot front of building"
    this.showMainGate = true;
    this.fBuilding = true;
    this.frontOFBuilding = webcamImage.imageAsDataUrl;
    this.imageBase64Building = webcamImage.imageAsBase64;
  }


  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  btnClickRecapture() {
    this.ISimageshow = true;
    this.fBuilding = false;
    this.showButton = false;
  }
  btnClickSave() {
    debugger;
    let val = window.confirm("Are you sure, you want to save");
    let lat: string;
    let long: string;
    if (val) {
      if (this.latitude && this.longitude) {
        lat = this.latitude;
        long = this.longitude;
      } else {
        lat = localStorage.getItem("latitude");
        long = localStorage.getItem("longitude");
      }
      const caseID = localStorage.getItem("caseID");
      const Auth = localStorage.getItem("isAuth");
      //snapType:string="ExternalBuildingIMage";
      let imgName = caseID + this.snapType;
      let data: IFtpImage = {
        CaseID: caseID,
        Image_tag_Name: "external",
        ImageName: imgName,
        Latitude: lat,
        Longitude: long,
        TimestampDate: "",
        ImgData: this.imageBase64Building
      }
      this.ngxService.start();
      ApiService.UploadFTPImage(data).then((responce: any) => {
        if (responce.status == 200) {
          this.ngxService.stop();
          if (this.snapType == "ExternalBuildingIMage") {
            this.snapType = "Front Of Main Gate";
            this.uiImageTest = "Front Of Main Gate";
            this.ISimageshow = true;
            this.fBuilding = false;
            this.showButton = false;
            this.btntext = "Take a snapshoot";
            this.btntitel = "Take a snapshoot Front Of Main Gate"
            Swal.fire('Success...', 'Please Take a snapshoot Front Of Main Gate', 'success');
          } else {
            Swal.fire('Success...', 'Save successfully', 'success');
            this.router.navigate(["internal"]);
          }
        }
      }).catch((error) => {
        this.ngxService.stop();
        Swal.fire('Oops...', 'Something went wrong', 'error');
      })
    }
  }
  public btnClickImageType(e: number) {
    this.ISimageshow = true;
    this.IfbuildingShow = false;
    let btnBuilding = document.getElementById("btnBuilding");
    let btnMainGate = document.getElementById("btnMainGate");
    if (e === 1) {
      this.imagetype = "FrontOFBuilding";
      btnBuilding.classList.add("active");
      btnMainGate.classList.remove("active");
    } else {
      this.btntext = "Capture";
      this.btntitel = "Capture snapshoot front of Main Gate"
      this.imagetype = "FrontOfMainGate";
      btnBuilding.classList.add("active");
      btnMainGate.classList.remove("active");
    }
  }
}
