import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent {
  title = 'Ftv Adroit Valuation';
  constructor(private router: Router , private ngxService:NgxUiLoaderService) { }
  ngOnInit() {
    //this.router.navigate(["first"]);
    this.getPosition();
  }
  getPosition = () => {
    new Promise((resolve, reject) => {    
     this.ngxService.start();
     navigator.geolocation.getCurrentPosition((position) => {
       localStorage.setItem("latitude", (position.coords.latitude).toString());
       localStorage.setItem("longitude", (position.coords.longitude).toString());      
       this.ngxService.stop();
       resolve(position.coords);
     }, (err) => {
       this.ngxService.stop();
       reject(err);
     });
   });
 }
}
