import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../service/service';
import { IFtpData, IvalidateToken } from '../../service/type';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SessionService } from '../../service/sessionGlobalVar';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: []
})
export class HomeComponent implements OnInit {
  basement: string="0";
  stit: string = "Select";
  floor: string="0";
  isShowFloor: boolean = true;
  LocationFloor: string;
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  constructor(private router: Router, private route: ActivatedRoute, private ngxService: NgxUiLoaderService, private session: SessionService) {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'id',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };


  }
  onBaseMentChange(){
    if (this.basement) {
      let i = 1;
      while (i <= +(this.basement)) {
        const val ='b'+i;
        this.dropdownList.push({ id: val });
        i++
      }
      this.selectedItems = [
        { id: 0 }
      ];
    }
  }
  onfloorChange() {
    if (this.floor) {
      let i = 0;
      while (i <= +(this.floor)) {
        this.dropdownList.push({ id: i });
        i++
      }
      this.selectedItems = [
        { id: 0 }
      ];
    }
  }
  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
  ngOnInit() {
    const Auth = localStorage.getItem("isAuth");
    if(!this.session.isLoggedIn){
      Swal.fire('Oops...', 'Invalid User', 'error');
    }
    // if (Auth === "No") {
    //   Swal.fire('Oops...', 'Invalid User', 'error');
    // }
  }
  btnClick() {
    if (!this.basement) {
      Swal.fire('Oops...', 'Please Select Basement', 'error');
    } else if (!this.floor) {
      Swal.fire('Oops...', 'Please Select Floor', 'error')
    } else if (this.stit === "Select") {
      Swal.fire('Oops...', 'Please Select Stit', 'error')
    }
    else {
      let val = window.confirm("Are You Sure You Want to save")
      if (val) {
        this.isShowFloor = false;
      }
    }
  }
  btnNxtClick() {
    const caseID = localStorage.getItem("caseID");
    const Auth = localStorage.getItem("isAuth");
    this.LocationFloor = "";
    for (const val of this.selectedItems) {
      if (this.LocationFloor === "") {
        this.LocationFloor = val;
      } else {
        this.LocationFloor += ',' + val;
      }
    }
    let val: IFtpData = {
      CaseID: caseID,
      LocationFloor: this.LocationFloor,
      Basement: this.basement,
      Stilt: this.stit,
      FloorNo: this.floor
    }
    if (!this.LocationFloor) {
      Swal.fire('Oops...', 'Please Select Location Floor', 'error');
      return false;
    }
    let bool = window.confirm("Are You Sure You Want to save");
    if (!bool) {
      return false;
    }
    this.ngxService.start();
    ApiService.FtpDataUpload(val).then((responce: any) => {
      this.ngxService.stop();
      Swal.fire('Success...', 'Data Save Successfully', 'success');
      this.router.navigate(["external"]);
    }).catch((e: any) => {
      this.ngxService.stop();
      Swal.fire('Oops...', 'Something went wrong please try again', 'error');
    });

  }
}
