import { Component, OnInit } from '@angular/core';
import { Router , ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-location-floor',
  templateUrl: './location-floor.component.html',
  styleUrls: []
})
export class LocationFloorComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
  }
  btnClick()
  {
    this.router.navigate(["external"]);
  }
}
