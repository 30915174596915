import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { updateStatus } from '../../service/type';
import ApiService from 'src/service/service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-share-pic-in-some-time',
  templateUrl: './share-pic-in-some-time.component.html',
  styleUrls: []
})
export class SharePicInSomeTimeComponent implements OnInit {
  lateTime: string;
  hideall:boolean=true;
  constructor(private router: Router , private ngxService: NgxUiLoaderService) { }
  ngOnInit() {
  }
  btnclick() {   
      const caseID = localStorage.getItem("caseID");
      const Auth = localStorage.getItem("isAuth");    
        let data: updateStatus = {
          CaseID: caseID,
          CaseStage: "390",
          CaseStageRemark: this.lateTime
        }
        this.ngxService.start();
        ApiService.updateStatus(data).then((responce) => {
          this.ngxService.stop();
          this.hideall=false;
          Swal.fire('success...', 'Update Successfully', 'success');
        }).catch((error) => {
          this.ngxService.stop();
          Swal.fire('Oops...', 'Something went wrong', 'error');
        })
  }
}
