import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { getLocaleTimeFormat, getLocaleDateTimeFormat } from '@angular/common';
import { IFtpImage, updateStatus } from '../../service/type';
import ApiService from 'src/service/service';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { timeout } from 'q';
@Component({
  selector: 'app-internal-photo',
  templateUrl: './internal-photo.component.html',
  styleUrls: []
})
export class InternalPhotoComponent implements OnInit {
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  nextPic: boolean = false;
  fBuilding: boolean = false;
  mainGate: boolean = false;
  showmainPic: boolean = false;
  showNext: boolean = false;
  btntext: string = "Take a snapshoot";
  btntitel: string = "Capture new snapshoot";
  isRecapture: boolean = false;
  latitude: string;
  longitude: string;
  hideall: boolean = true;
  iscall: boolean = true;
  ImageWebCam: any;
  InternalImage: any;
  morePic: number = 5;
  hideInterNal: boolean = true;
  hideBUtton: boolean = false;


  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  public internalPic: any[] = [];
  public imageBase64Internal: any[] = [];

  public kitchenPic: any;
  public imageBase64Kitchen: any;

  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  imagetype: string;
  valueOFImage: string;
  ISimageshow: boolean = false;

  constructor(private router: Router, private ngxService: NgxUiLoaderService) { }
  ngOnInit() {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        if (this.multipleWebcamsAvailable) {
          this.videoOptions = {
            facingMode: 'environment' // Instruct the browser to use the back camera
          };
        }
      });
  }
  getPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = (position.coords.latitude).toString();
        this.longitude = (position.coords.longitude).toString();
        resolve(position.coords);
      }, (err) => {
        reject(err);
      });
    });
  }
  SaveAllPic() {
    let val = window.confirm("Are you sure, you want to save");
    let lat: string;
    let long: string;
    if (val) {
      if (this.latitude && this.longitude) {
        lat = this.latitude;
        long = this.longitude;
      } else {
        lat = localStorage.getItem("latitude");
        long = localStorage.getItem("longitude");
      }
      const caseID = localStorage.getItem("caseID");
      const Auth = localStorage.getItem("isAuth");
      let imgName = caseID + "InternalBuildingIMage";
      let i: number = 0;
      for (let val of this.imageBase64Internal) {

        let data: IFtpImage = {
          CaseID: caseID,
          Image_tag_Name: "internal",
          ImageName: imgName + this.morePic,
          Latitude: lat,
          Longitude: long,
          TimestampDate: "",
          ImgData: val
        }
        new Promise((resolve, reject) => {
          this.ngxService.start();
          ApiService.UploadFTPImage(data).then((responce: any) => {
            this.ngxService.stop();
            i += 1;
            if (i == 4 && this.iscall) {
              this.iscall = false;
              this.savesuccess();
            }
            resolve(responce);
          }).catch((error) => {
            this.ngxService.stop();
            Swal.fire('Oops...', 'Something went wrong', 'error');
          })
        })
      }

    }
  }
  SaveSinglePic() {
    let val = window.confirm("Are you sure, you want to save");
    let lat: string;
    let long: string;
    if (val) {
      if (this.latitude && this.longitude) {
        lat = this.latitude;
        long = this.longitude;
      } else {
        lat = localStorage.getItem("latitude");
        long = localStorage.getItem("longitude");
      }
      const caseID = localStorage.getItem("caseID");
      const Auth = localStorage.getItem("isAuth");
      let imgName = "Internal_" + caseID + "_";
      let i: number = 0;
      let data: IFtpImage = {
        CaseID: caseID,
        Image_tag_Name: "internal",
        ImageName: imgName + this.morePic,
        Latitude: lat,
        Longitude: long,
        TimestampDate: "",
        ImgData: this.ImageWebCam
      }
      this.ngxService.start();
      ApiService.UploadFTPImage(data).then((responce: any) => {
        this.ngxService.stop();
        this.isRecapture = false;
        this.showmainPic = false;
        this.ISimageshow = true;
        this.hideBUtton = false;
        this.btntext = "Take a snapshoot";
        this.btntitel = "Capture new snapshoot";
        this.nextPic = false;
        this.morePic = this.morePic - 1;
        if (this.morePic == 0) {
          this.savesuccess()
          this.router.navigate(["closed"]);
        }
        else if (this.morePic < 0) {
          this.router.navigate(["closed"]);
        }
        else {
          Swal.fire("sucess", "You can upload " + this.morePic + " more picture", "success");
        }

      }).catch((error) => {
        this.ngxService.stop();
        // Swal.fire('Oops...', 'Something went wrong', 'error');
      })
    }
  }
  closeWindow() {
    setTimeout(function () { window.close() }, 3000);
  }
  savesuccess() {
    this.ngxService.start();
    const caseID = localStorage.getItem("caseID");
    let data: updateStatus = {
      CaseID: caseID,
      CaseStage: "387",
      CaseStageRemark: "no"
    }
    ApiService.updateStatus(data).then((responce: any) => {
      localStorage.clear();
      Swal.fire('Thanks...', 'your data save successfully', 'success');
      this.closeWindow()
      this.ngxService.stop();
      this.hideall = false;
      this.ISimageshow = false;
      this.showmainPic = false
      Swal.fire('Thanks...', 'your data save successfully', 'success');
    }).catch((error) => {
      //  Swal.fire('Oops...', 'Something went wrong', 'error');
      // Swal.fire('Thanks...', 'your data save successfully', 'success');
      // localStorage.clear();
      // this.ngxService.stop();
      // this.hideall = false;
      // this.ISimageshow = false;
      // this.showmainPic = false
      this.ngxService.stop();
    })
  }
  MoveHome() {
    this.router.navigate(["home"]);
  }
  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.btntext = "Recapture";
    this.btntitel = "Recapture snapshoot"
    this.nextPic = true;
    this.showmainPic = true;
    this.ISimageshow = false;
    this.hideBUtton = true;
    // if (this.isRecapture) {
    //   this.internalPic = this.internalPic.slice(0, this.internalPic.length - 1);
    //   this.imageBase64Internal = this.imageBase64Internal.slice(0, this.imageBase64Internal.length - 1);
    // }
    // if (this.internalPic.length >= 4) {
    //   this.showNext = true;
    // }
    // if (this.internalPic.length < 5) {
    //   this.fBuilding = true;
    //   this.internalPic.push(webcamImage.imageAsDataUrl);
    //   this.imageBase64Internal.push(webcamImage.imageAsBase64);
    //   this.isRecapture = true;
    // }
    // else {
    //   Swal.fire('Oops...', 'You can Upload only 5 pic ', 'warning');
    // }
    this.ImageWebCam = webcamImage.imageAsBase64;
    this.InternalImage = webcamImage.imageAsDataUrl;
    const p = document.getElementById("divimg");
    p.focus();
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public btnNextClick() {
    this.SaveSinglePic();

  }
  public btnClickImageType() {
    this.ISimageshow = true;
    this.hideInterNal = false;
  }
  btnClickRecapture() {
    this.nextPic = false;
    this.showmainPic = false;
    this.ISimageshow = true;
    this.hideBUtton = false;
  }
}
