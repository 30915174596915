import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../service/service';
import { IFtpData, IvalidateToken } from '../../service/type';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.css']
})
export class InstructionComponent implements OnInit {
  istrue: boolean = false;
  lat: string;
  long: string;
  caseID:string;
  token:string;
  constructor(private router: Router, private ngxService: NgxUiLoaderService ,private route: ActivatedRoute) { }

  ngOnInit() {
   const Auth = localStorage.getItem("isAuth");
  }
  btnClick(){
    this.router.navigate(["home"]);
  }
}
