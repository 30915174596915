
import { ApiService } from '../service/service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    public ISlogin: boolean = false;
    constructor(private router: Router) { }
    token: string = "";
    get isLoggedIn(): boolean {      
        const isAUth=localStorage.getItem("isAuth");
        localStorage.setItem("isAuth", "Yes");
        if (isAUth=="Yes") {
            return true;
        }
        else {
            localStorage.clear();
            window.close();
            return false;
        }
    }
}
export const APIURL: string = "https://ftv.adroitvaluation.in//webapi//";
//export const APIURL: string = "http://adroitvaluation.in:8087//";


