import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../service/service';
import { IFtpData, IvalidateToken } from '../../service/type';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-closed',
  templateUrl: './closed.component.html',
  styleUrls: []
})
export class ClosedComponent implements OnInit {

  istrue: boolean = false;
  lat: string;
  long: string;
  caseID:string;
  token:string;
  constructor(private router: Router, private ngxService: NgxUiLoaderService ,private route: ActivatedRoute) {
    this.getPosition(); }

    ngOnInit() {
      this.route.params.subscribe(params => {
        console.log(params)      
        if (params.id) {
         this.caseID=params.id;
         this.token=params.zzz;
        }else{
          this.caseID="233888";
          this.token="D2FC3747-DE55-4542-9";
        }
      });
      if(!this.caseID)  {
        Swal.fire('Oops...', 'Something went wrong', 'error');
        window.close();
      } 
      localStorage.setItem("caseID", this.caseID);  
      let val: IvalidateToken = {
        CaseID: this.caseID,
        Token: this.token
      }
     this.getAuthontication(val);
    }
    btnclick(val: number) {
      if (val === 0) {
        if (this.lat  && this.long) {
          this.router.navigate(["home"]);
        } else {
          this.lat = localStorage.getItem("latitude");
          this.long = localStorage.getItem("longitude");
          if (this.lat  && this.long) {
            this.router.navigate(["home"]);
          }else{
            Swal.fire('Oops...', 'Something went wrong', 'error');
          }
        }
      } else {
        this.router.navigate(["notAvailable"]);
      }
    }
    getPosition = () => {
       new Promise((resolve, reject) => {    
        this.ngxService.start();
        navigator.geolocation.getCurrentPosition((position) => {
          localStorage.setItem("latitude", (position.coords.latitude).toString());
          localStorage.setItem("longitude", (position.coords.longitude).toString());
          this.lat = (position.coords.latitude).toString();
          this.long = (position.coords.longitude).toString()
          this.ngxService.stop();
          resolve(position.coords);
        }, (err) => {
          this.ngxService.stop();
          reject(err);
        });
      });
    }
    getAuthontication(val: IvalidateToken) {
      this.ngxService.start();
     // this.istrue = true;
      ApiService.ValidateToken(val).then((responce: any) => {
        localStorage.setItem("isAuth", "Yes");
        this.ngxService.stop();
        this.istrue = true;
      }).catch((e: any) => {
        localStorage.setItem("isAuth", "No");
        this.ngxService.stop();
        //   this.istrue = false;
      //  Swal.fire('Oops...', 'Something went wrong', 'error');
        // alert("Error" + e.message);
      });
    }
  }
  