import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ExternalPhotoComponent } from './external-photo/external-photo.component';
import { InternalPhotoComponent } from './internal-photo/internal-photo.component';
import { RouterModule, Routes } from '@angular/router';
import { WebcamModule } from 'ngx-webcam';
import { FirstComponent } from './first/first.component';
import { NotAvailableComponent } from './not-available/not-available.component';
import { AlternatenoComponent } from './alternateno/alternateno.component';
import { SharePicInSomeTimeComponent } from './share-pic-in-some-time/share-pic-in-some-time.component';
import { LocationFloorComponent } from './location-floor/location-floor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxUiLoaderService ,NgxUiLoaderModule } from 'ngx-ui-loader';
import { ClosedComponent } from './closed/closed.component';
import { InstructionComponent } from './instruction/instruction.component';
const appRoutes: Routes = [
  { path: "", redirectTo: "first", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  { path: "external", component: ExternalPhotoComponent },
  { path: "internal", component: InternalPhotoComponent },
  { path: "first", component: FirstComponent },
  { path: "notAvailable", component: NotAvailableComponent },
  { path: "alternateNo", component: AlternatenoComponent },
  { path: "sharePicInSomeTime", component: SharePicInSomeTimeComponent },
  { path: "locationfloor", component: LocationFloorComponent },
  {path:"closed",component:ClosedComponent},
  {path:"Instruction",component:InstructionComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ExternalPhotoComponent,
    InternalPhotoComponent,
    FirstComponent,
    NotAvailableComponent,
    AlternatenoComponent,
    SharePicInSomeTimeComponent,
    LocationFloorComponent,
    ClosedComponent,
    InstructionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    WebcamModule,
    FormsModule,
    NgMultiSelectDropDownModule,
    NgxUiLoaderModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
  ],
  providers: [NgxUiLoaderService],
  bootstrap: [AppComponent]
})
export class AppModule { }
